import React from "react";
import MockupImage from "../../assets/images/mockup.png"
import GooglePlay from "../../assets/images/download-btn.png";
import AppleStore from "../../assets/images/apple-logo.png"
import "./heroheader.css"

export default function HeroHeader() {

    return (
        <div className="hero-container">
            <div className="left-description">
                <h1>
                    <span className="heading-line-first">Learn</span>
                    <br />
                    <span className="heading-line-second">Practice</span>
                    <br />
                    <span className="heading-line-third">Succeed</span>
                </h1>
            </div>
            <img src={MockupImage} className='image' alt="Create Flashcards screen"/>
            <div className="right-description">
                <h1>With Dimoly</h1>
                <p>
                Empowering students to reach their full potential through personalized learning platform.
                </p>
                <br/>
                <a href="https://play.google.com/store/apps/details?id=com.mbahjavis.dimoly" target="_blank">
                    <img src={GooglePlay} className="google-play" alt="Download on Google Playstore" /> </a>
                <a href="#"><img src={AppleStore} className="google-play" target="_blank" alt="Download on Apple Appstore"/> </a>
            </div>
        </div>
    )
}
