import React, { useState } from 'react';
import KeyboardDoubleArrowDownRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded';
import KeyboardDoubleArrowUpRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowUpRounded';
import "./faq.css";

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="faq">
      <div className="faq-item" onClick={() => setIsActive(!isActive)}>
        <p>{title}</p>
        <div>{isActive ? <span className="faq-expand1"><KeyboardDoubleArrowUpRoundedIcon /></span>
        : <span className="faq-expand"><KeyboardDoubleArrowDownRoundedIcon /></span>}</div>
      </div>
      {isActive && <div className='faq-answer'><p>{content}</p></div>}
    </div>
  );
};

export default Accordion;