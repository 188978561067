import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { BlockMath, InlineMath } from 'react-katex';
import '../App.css';
import 'katex/dist/katex.min.css'

const renderLatex = (text) => {
    const regex = /(?:\$\$([\s\S]*?)\$\$|\\\(([\s\S]*?)\\\))/g;
    let match;
    const parts = [];
    let lastIndex = 0;

    while ((match = regex.exec(text)) !== null) {
        const fullMatch = match[0];
        const inlineMath = match[2];
        const displayMath = match[1];

        if (displayMath) {
            parts.push(<BlockMath key={lastIndex} math={displayMath} />);
        } else if (inlineMath) {
            parts.push(<InlineMath key={lastIndex} math={inlineMath} />);
        }

        const index = match.index;
        const nonMathPart = text.slice(lastIndex, index);
        parts.push(<span key={lastIndex + 1}>{nonMathPart}</span>);
        lastIndex = index + fullMatch.length;
    }

    if (lastIndex < text.length) {
        parts.push(<span key={lastIndex}>{text.slice(lastIndex)}</span>);
    }

    return parts;
};

const DisplaySolutionComponent = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const queryParams = queryString.parse(window.location.search);
        const { user, token } = queryParams;
        console.log(user, token);

        const fetchData = async () => {
            try {
                const baseUrl = 'https://us-central1-snapp-155ab.cloudfunctions.net/app/api/v1';
                const response = await fetch(`${baseUrl}/solution/get?user=${user}&token=${token}`);
                const responseData = await response.json();
                console.log(responseData);
                setData(responseData.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        if (user && token) {
            fetchData();
        }
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '100px', paddingLeft: '40px', paddingRight: '40px' }}>
            {loading ? (
                <div>
                    <p>Loading...</p>
                </div>
            ) : data ? (
                <div className='solution-div'>
                    <p>{renderLatex(data.question)}</p>
                    <ol>
                        {data.steps.map((step, index) => (
                            <li key={index}>
                                <h4>{renderLatex(step.title)}</h4>
                                <p>{renderLatex(step.explanation)}</p>
                                <p>{renderLatex(step.example)}</p>
                            </li>
                        ))}
                    </ol>
                    <p>{data.suggestion}</p>
                </div>
            ) : (
                <div>No data available</div>
            )}
        </div>
    );
};

export default DisplaySolutionComponent;
