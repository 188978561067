import React from "react";
import Accordion from "./Accordion";
import "./faq.css";

export default function FAQComponent() {

    const Faqs = [
        {
            id: "0",
            question: "What is Dimoly?",
            answer: "Dimoly is an educational platform that provides students with learning tools such as flashcards, past exam questions and an AI assistant to help them when studying. The platform also includes a time table scheduler to manage studying habits and increase productivity. Dimoly aims to make learning entertaining and exciting for students, educators, and parents.",
        },
        {
            id: "1",
            question: "What is an AI assistant",
            answer: "AI assistant is a chatbot like ChatGPT that can assists students while they are studying. It can provide answers to any question and also quick access to valuable information",
        },
        {
            id: "2",
            question: "What is a flashcard",
            answer: "A flashcard is a card with information on it, used as an aid for memorization. One side of the card typically contains a question or prompt, and the other side contains the answer or response. It helps the user to memorize and recall the information faster by organizing and reviewing the information",
        },
        {
            id: "3",
            question: "Can I create my own flashcards?",
            answer: "Absolutely YES!. \nWith dimoly, you can create as my many flashcards as you want. After you finish creating a flashcard, you are given two options: \n1. Chose to publish your flashcards so that other users can use it or\n2. Keep them  private were only you can access them. \nFlashcards can be use to study any subject.",
        },
        {
            id: "4",
            question: "Can I access past questions and flashcards offline?",
            answer: "Yes \nThe app works both offline and online. Once you view a question or flashcard, it becomes avialable oflline.",
        },
        {
            id: "5",
            question: "Have more questions?",
            answer: "Send us an email at hello@dimoly.app with your questions and or worries.",
        },
    ]

    return (
        <div id="FAQ">
            <div className="faq-container" id="#FAQ">
                <h1 className="feature-h1">Frequently asked questions</h1>
                <p className="feature-description">Commonly asked questions</p>
                {Faqs.map(faq => 
                    <Accordion title={faq.question} content={faq.answer} key={faq.id}/>
                )
                }
                
            </div>
        </div>
    )

}
