import React from "react";
import "./partnerss.css";
import Microsoft from "../../assets/images/microsoft.png";
import CDIC from "../../assets/images/cdic.png";

export default function Partners() {

    return (
        <div className="partners-container">
            <h1 className="feature-h1">Our Partners</h1>
            <div className="partners">
            <img src={Microsoft} alt="Microsoft for start ups" className="partner-logo" />
            <img src={CDIC} alt="CDIC" className="partner-logo" />
            </div>
        </div>
    )
}