import './App.css';
import Home from './pages/Home';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import DisplaySolutionComponent from './pages/Share';
import DeleteAccount from './pages/DeleteAccount';
import FooterBanner from './components/Footer/FooterBanner';

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="share" element={<DisplaySolutionComponent />} />
        <Route path="account/delete" element={<DeleteAccount />} />
      </Routes>
      <FooterBanner />
    </div>
  );
}

export default App;
