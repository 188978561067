import React from "react";
import '../App.css'
import FooterBanner from "../components/Footer/FooterBanner";

export default function Privacy() {

    return (
        <div className="policy-container" >
            <h2 className="feature-h1">Privacy Policy</h2>
            <section className="section">
                <div >
                    Dimoly is committed to maintaining robust privacy protections for its users. Our
                    Privacy Policy (“Privacy Policy”) is designed to help you understand how we collect,
                    use and safeguard the information you provide to us and to assist you in making
                    informed decisions when using our Service. For purposes of this Agreement, “Site”
                    refers to our platform, which can be accessed using our mobile app from Google Play
                    store “Service” refers to the our services accessed via the Site. The terms “we,”
                    “us,” and “our” refer to Dimoly. “You” refers to you, as a user of our Site or our
                    Service. By accessing our Site or our Service, you accept our Privacy Policy and
                    Terms of Use and you consent to our collection, storage, use and disclosure of your
                    Personal Information as described in this Privacy Policy.
                    <br /><br />
                    <h3>I. INFORMATION WE COLLECT</h3>
                    We collect “Non-Personal Information” and “Personal Information.” Non-Personal
                    Information includes information that cannot be used to personally identify you,
                    such as anonymous usage data, general demographic information we may collect,
                    referring/exit pages and URLs, platform types, preferences you submit and
                    preferences that are generated based on the data you submit and number of clicks.
                    Personal Information includes your email which you submit to us through the
                    registration process at the Site and an optional phone number when editing your user
                    information on the Site.
                    <br /><br />
                    <h4>1. Information collected via Technology</h4>
                    To activate the Service you do not need to submit any Personal Information other
                    than your email address. To use the Service thereafter, you do not need to submit
                    further Personal Information. However, in an effort to improve the quality of the
                    Service, we track information provided to us by our software application when you
                    view or use the Service that does not personally identify you. 2. Information you
                    provide us by registering for an account To become a user to our Service you will
                    need to create a personal profile. You can create a profile by registering with the
                    Service and entering your email address, and creating a user name and a password. By
                    registering, you are authorizing us to collect, store and use your email address in
                    accordance with this Privacy Policy.
                    <br /><br />
                    <h4>3. Children’s Privacy</h4>
                    The Site and the Service are not directed to anyone under the age of 13. The Site
                    does not knowingly collect or solicit information from anyone under the age of 13,
                    or allow anyone under the age of 13 to sign up for the Service. In the event that we
                    learn that we have gathered personal information from anyone under the age of 13
                    without the consent of a parent or guardian, we will delete that information as soon
                    as possible. If you believe we have collected such information, please contact us at
                    javismbah2025@gmail.com
                    <br /><br />
                    <h3>II. HOW WE USE AND SHARE INFORMATION</h3>
                    Personal Information: Except as otherwise stated in this Privacy Policy, we do not
                    sell, trade, rent or otherwise share for marketing purposes your Personal
                    Information with third parties without your consent. We do share Personal
                    Information with vendors who are performing services for us, such as the servers for
                    our email communications who are provided access to user’s email address for
                    purposes of sending emails from us. Those vendors use your Personal Information only
                    at our direction and in accordance with our Privacy Policy. In general, the Personal
                    Information you provide to us is used to help us communicate with you. For example,
                    we use Personal Information to contact users in response to questions, solicit
                    feedback from users, provide technical support, and inform users about promotional
                    offers. We may share Personal Information with outside parties if we have a
                    good-faith belief that access, use, preservation or disclosure of the information is
                    reasonably necessary to meet any applicable legal process or enforceable
                    governmental request and where ever it is good for us you; to enforce applicable
                    Terms of Service, including investigation of potential violations; address fraud,
                    security or technical concerns; or to protect against harm to the rights, property,
                    or safety of our users or the public as required or permitted by law. Non-Personal
                    Information In general, we use Non-Personal Information to help us improve the
                    Service and customize the user experience. We also aggregate Non-Personal
                    Information in order to track trends and analyze use patterns on the Site. This
                    Privacy Policy does not limit in any way our use or disclosure of Non-Personal
                    Information and we reserve the right to use and disclose such Non-Personal
                    Information to our partners, advertisers and other third parties at our discretion.
                    In the event we undergo a business transaction such as a merger, acquisition by
                    another company, or sale of all or a portion of our assets, your Personal
                    Information may be among the assets transferred. You acknowledge and consent that
                    such transfers may occur and are permitted by this Privacy Policy, and that any
                    acquirer of our assets may continue to process your Personal Information as set
                    forth in this Privacy Policy. If our information practices change at any time in the
                    future, we will post the policy changes to the Site so that you may opt out of the
                    new information practices. We suggest that you check the Site periodically if you
                    are concerned about how your information is used.
                    <br /><br />
                    <h3>III. HOW WE PROTECT INFORMATION</h3>
                    We implement security measures designed to protect your information from
                    unauthorized access. Your account is protected by your account password and we urge
                    you to take steps to keep your personal information safe by not disclosing your
                    password and by logging out of your account after each use. We further protect your
                    information from potential security breaches by implementing certain technological
                    security measures including encryption, firewalls and secure socket layer
                    technology. However, these measures do not guarantee that your information will not
                    be accessed, disclosed, altered or destroyed by breach of such firewalls and secure
                    server software. By using our Service, you acknowledge that you understand and agree
                    to assume these risks.
                    <br /><br />
                    <h3>IV. LINKS TO OTHER WEBSITES</h3>
                    As part of the Service, we may provide links to or compatibility with other websites
                    or applications. However, we are not responsible for the privacy practices employed
                    by those websites or the information or content they contain. This Privacy Policy
                    applies solely to information collected by us through the Site and the Service.
                    Therefore, this Privacy Policy does not apply to your use of a third party website
                    accessed by selecting a link on our Site or via our Service. To the extent that you
                    access or use the Service through or on another website or application, then the
                    privacy policy of that other website or application will apply to your access or use
                    of that site or application. We encourage our users to read the privacy statements
                    of other websites before proceeding to use them.
                    <br /><br />
                    <h3>V. CHANGES TO OUR PRIVACY POLICY</h3>
                    We reserve the right to change this policy and our Terms of Service at any time. We
                    will notify you of significant changes to our Privacy Policy by sending a notice to
                    the primary email address specified in your account or by placing a prominent notice
                    on our site. Significant changes will go into effect 30 days following such
                    notification. Non-material changes or clarifications will take effect immediately.
                    You should periodically check the Site and this privacy page for updates.
                    <br /><br />
                    <h3>VI. CONTACT US</h3>
                    If you have any questions regarding this Privacy Policy or the practices of this
                    Site, please contact us by sending an email to
                    <a href="mailto:hello@dimoly.app"> hello@dimoly.app</a> <br /><br />
                    Last Updated: This Privacy Policy was last updated on January/15/2023.
                </div>
            </section>
        </div>
    )
}