import React from "react";
import './features.css';
import { AutoAwesomeMosaicRounded, ArticleRounded, AccessTimeFilledRounded, ChatTwoTone } from "@mui/icons-material";

export default function AppFeatures() {

    const features = [
        { icon: <ChatTwoTone />, title: "AI assistant", description: "AI assistant is a valuable tool for students while they are studying as it can help them get answers to any question and provide quick access to valuable information." },
        { icon: <AutoAwesomeMosaicRounded />, title: "Flashcards", description: "Flashcards are a highly effective study tool as they allow for active recall and repetition, which strengthens memory and helps in memorization" },
        { icon: <ArticleRounded />, title: "Past questions", description: "Improves familiarity with format and style of exams, identifies strengths and weaknesses, tests understanding, and develops problem-solving skills" },
        { icon: <AccessTimeFilledRounded/>, title: "Timetable manager", description: "Time table manager helps students prioritize tasks and make the most of study time, avoiding procrastination and distractions effectively, reducing stress" },
    ]

    return (
        <div className="container" id="Features">
            <h1 className="feature-h1">Amazing features</h1>
            <p className="feature-description">
            The ultimate educational app for students. Get interactive flashcards, past exam questions, time table scheduler, verified home tutors and progress tracking all in one easy-to-use platform.
            </p>
            <div className="feature-container">
                {features.map(item => <div className="feature" key={item.title}>
                    <div className="wrap-title">
                        <span className="feature-num">{item.icon}</span>
                        <p className="feature-title">{item.title}</p>
                    </div>
                    <p>{item.description}</p>
                </div>
                )}
            </div>
        </div>
    )

} 
