import React from "react";
import "./footer.css";
import { FacebookRounded } from "@mui/icons-material";
import { Twitter } from "@mui/icons-material";
import { LinkedIn } from "@mui/icons-material";
import GooglePlay from "../../assets/images/google-black.png";
import AppleStore from "../../assets/images/apple-black.png";
import { Link } from "@mui/material";

export default function FooterBanner() {

    var today = new Date();
    var year = today.getFullYear();

    return (
        <div className="banner-container">
            <div className="banner-bg">
                <h2 className="banner-h2">Learn anytime, anywhere</h2>
                <p>Download the app now and take control over your education!</p>
                <span>
                <Link href="https://play.google.com/store/apps/details?id=com.mbahjavis.dimoly" underline="none" target="_blank" rel="noopener"><img src={GooglePlay} className="google-play" /> </Link>
                <Link href="#" underline="none" ><img src={AppleStore} className="google-play" /> </Link>
                </span>
            </div>
            <div className="footer-container" id="Contact">
                <span className="socail-wrapper">
                    <Link href="https://www.facebook.com/dimolyApp" underline="none" target="_blank" rel="noopener">
                        <span className="facebook-icon">
                            <FacebookRounded />
                        </span>
                    </Link>
                    <Link href="https://mobile.twitter.com/dimoly_app" underline="none" target="_blank" rel="noopener">
                        <span className="twitter-icon">
                            <Twitter />
                        </span>
                    </Link>
                    <Link  href="https://www.linkedin.com/company/dimoly/" underline="none" target="_blank" rel="noopener">
                        <span className="facebook-icon">
                            <LinkedIn />
                        </span>
                    </Link>
                </span>
                <span className="policy-wrapper">
                    <a href="/privacy"><p className="p">Privacy Policy</p></a>
                    <a href="/terms"><p>Terms & Conditions</p></a>
                </span>
            </div>
            <span className="copy-right"><p>Copy right © {year} ❤️ Dimoly</p></span>
        </div>
    )
}