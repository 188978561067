import React from 'react';
import '../App.css';

const DeleteAccount = () => {
    return (
        <div  style={{ display: 'block', justifyContent: 'center', alignItems: 'center', marginTop: '100px', paddingLeft: '70px', paddingRight: '70px' }}>
            <h3>Account Management</h3>
            <p>To request your accont to be deleted, please send us an email to hello@dimoly.com with your full name, username and email address used on Dimoly learning app.</p>
        </div>
    );
}

export default DeleteAccount;
