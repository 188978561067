import React from "react";
import MockupImage from "../../assets/images/shot1.png";
import MockupImage1 from "../../assets/images/time.png";
import MockupImage3 from "../../assets/images/progress1.png";
import { AccessTimeFilledRounded } from "@mui/icons-material";
import "./features.css";

export default function ImageTextComponent() {

    return (
        <div className="image-text-wrapper" id="About">
            <div className="image-text-container">
                <img src={MockupImage} className='image-style1' alt="Chat with AI assistant" />
                <div className="text-wrapper">
                    <h1 className="feature-h1">Everything you need to excel!</h1>
                    <p className="image-text">A powerful AI assistant to help you answer questions, do homework or study. It can also explain complex topics to you by going through them step by step</p>
                    <br></br>
                    <div className="wrap-title">
                        <span className="feature-desc"><AccessTimeFilledRounded /> </span>
                        <p className="feature-title">Personalized learning</p>
                    </div>
                    <p>Combining the best of AI and studying, Dimoly is designed<br></br>to help you learn at just the right level and pace</p>
                </div>
            </div>
            <br />
            <div className="image-text-container1">
                <div className="text-wrapper">
                    <h1 className="feature-h1">Learn differently</h1>
                    <p className="image-text">Effective learning doesn’t have to be boring! Build your skills each day with engaging flashcards and past questions</p>
                    <br></br>
                    <div className="wrap-title">
                        <span className="feature-desc"><AccessTimeFilledRounded /> </span>
                        <p className="feature-title">Stay motivated</p>
                    </div>
                    <p>We make it easy to form a learning habit, with<br></br>exciting features, fun challenges and reminders.</p> 
                </div>
                <img src={MockupImage1} className='image-style2' alt="Time table manager screen" />
            </div>
            <br />
            <div className="image-text-container">
                <img src={MockupImage3} className='image-style1' alt="Track your learning progress" />
                <div className="text-wrapper">
                    <h1 className="feature-h1">Track your learning progress</h1>
                    <p className="image-text">Dimoly learning app allows you to easily track your learning progress, helping you to stay motivated and achieve your goals. With personalized insights and feedback, you can optimize your study routine and maximize your potential.</p>
                    <br></br>
                    <div className="wrap-title">
                        <span className="feature-desc"><AccessTimeFilledRounded /> </span>
                        <p className="feature-title">Get updates</p>
                    </div>
                    <p>Get updates on how much work you have <br></br>covered over a period of time</p>
                </div>
            </div>
        </div>
    )
}
