import React from "react";
import '../App.css';
import HeroHeader from '../components/header/Heroheader';
import AppFeatures from '../components/Features/AppFeatures';
import ImageTextComponent from '../components/Features/ImageTextComponent';
import FAQComponent from '../components/Faq/FAQComponent';
import FooterBanner from '../components/Footer/FooterBanner';
import Partners from "../components/partners/Partners";

export default function Home() {

    return (
        <div className="app-container" id="Home">
            <header className='hero-section'>
                <HeroHeader />
            </header>
            <AppFeatures />
            <ImageTextComponent />
            <FAQComponent />
            <Partners />
        </div>
    )
}