import React, { useEffect, useState } from "react";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Logo from '../assets/images/logo.png';
import "./navbar.css";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default function Navbar() {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const [navbar, setNavbar] = useState(false)

    const changeBackground = () => {
        if(window.scrollY >= 66) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    useEffect(() => {
        changeBackground()
        window.addEventListener("scroll", changeBackground)
    })

    const headerList = [
        { id: 0, name: 'Home' },
        { id: 1, name: 'Features'}, 
        { id: 2, name: 'About' }, 
        { id: 3, name: 'Contact' }, 
        { id: 4, name: 'FAQ' }
    ]

    const [selectedPage, setSelectedPage] = useState('Home')

    const selectPage = (page) => {
        setSelectedPage(page)
    }

    const toggleNav = () => {
        setToggleMenu(!toggleMenu);
    };

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", changeWidth);

        return () => {
            window.removeEventListener("resize", changeWidth);
        };
    }, []);

    return (
        <nav className={navbar ? "nav-active" : "nav"}>
            <span href="/" className="logo-wrapper">
                <a href="/">
                    <img src={Logo} className="logo" alt='logo' />
                </a>
                <span>Dimoly</span>
            </span>
            {(toggleMenu || screenWidth > 1000) && (
                <ul className="list">
                    {headerList.map(item => 
                        <div key={item.id} onClick={() => selectPage(item.name)} className="list-item">
                            {selectedPage === item.name 
                            ?   <AnchorLink href={"#"+item.name}>
                                    <li className="item-selected">{item.name}</li>
                                </AnchorLink>
                                : <AnchorLink href={"#"+item.name}>
                                    <li className="items" >{item.name}</li></AnchorLink>
                            }
                        </div>
                        
                    )}
                </ul>
            )}
            {screenWidth > 1000 ? (
                <a href="https://play.google.com/store/apps/details?id=com.mbahjavis.dimoly" target='_blank' className="download-btn">
                    <button className="download-btn-style" href="#">Download</button>
                </a>
            ) : toggleMenu ? (
                <CloseRoundedIcon className="btn" onClick={toggleNav} />
            ) : (
                <MenuRoundedIcon className="btn" onClick={toggleNav} />
            )}
        </nav>
    );
}
